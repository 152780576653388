<template>
  <!-- //发布教师文章 -->
  <div class="pushBox">
    <div class="ArrBox" :class="routeName == 'addReportIndex' ? 'w_layout' : ''">
      <!-- 导航 -->
      <div class="title" v-if="routeName == 'addReportIndex'">
        <p>
          当前位置：四名工作室 > 活动报道 >
          <span>{{ this.$route.query.id ? '修改' : '新增' }}活动报道</span>
        </p>
      </div>
      <div class="BoxItem">
        <h4>{{ this.$route.query.id ? '修改' : '新增' }}活动报道</h4>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" placeholder="请输入标题(限60个字符)" style="width: 260px"></el-input>
          </el-form-item>

          <el-form-item label="类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择活动类型" class="sid" @change="changetype" style="width: 260px">
              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>

            <el-select v-model="form.nature" placeholder="请选择活动形式" class="sid" style="width: 260px">
              <el-option v-for="item in typeList2" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开展日期" prop="start_date">
            <div class="df">
              <el-date-picker v-model="form.start_date" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd">
              </el-date-picker>
              <div class="ml20" v-if="form.type == 42">
                为期
                <el-input onkeyup="value=value.replace(/[^\d.]/g,'')" v-model="form.days" style="width: 120px"></el-input>
                天
              </div>
            </div>
          </el-form-item>

          <el-form-item label="活动级别" prop="report_level">
            <el-radio-group v-model="form.report_level">
              <el-radio v-for="(item, index) in certificatList" :key="index" :value="item.id" :label="item.id">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="封面" :required="true">
            <dc-upload style="position: relative" ref="activityUpload" :dcAutoUpload="true" :show-file-list="false"
              dcdom="activityUpload" :dcConfig="dcUploadConfig" @dc-success="dcUploadSuccess"
              @dc-progress="dcProgressTrue = false" v-if="dcUploadConfig.key && dcUploadConfig.notify_url"
              accept=".jpg,.png,.swf,.bmp,.jpeg,.JPG,.PNG,.SWF,.BMP,.JPEG">
              <div class="upload-img-box">
                <img :src="form.cover_url" v-if="form.cover_id" class="upload-img" />
                <i class="el-icon-plus upload-noData" v-else></i>
              </div>
            </dc-upload>
          </el-form-item>

          <el-form-item label="摘要" prop="abstract">
            <el-input v-model="form.abstract" placeholder="请输入摘要(限64个字符)" type="textarea"
              :autosize="{ minRows: 6, maxRows: 6 }" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item label="正文模版" v-if="!this.$route.query.id">
            <el-select v-model="tempId" placeholder="请选择" class="sid" style="width: 260px" @change="changeTemp">
              <el-option v-for="item in templateList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <div class="pr80">
              <Editor v-model="form.content"></Editor>
            </div>
          </el-form-item>

          <el-form-item>
            <div style="margin-top: 10px">
              <el-button type="primary" @click="submitForm('form')" :loading="save_loading_btn">提交</el-button>
              <el-button @click="resetForm('form')">取消</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { articleTemplate } from "@/api/teacherStudio/wechatApi"
import { debounce, throttle } from "@/utils/utils.js";
export default {
  data() {
    return {
      save_loading_btn: false,
      //分类列表
      typeList: [],
      typeList2: [],
      templateList: [],
      tempId: "",
      oldId: "",
      //表单
      form: {
        title: "",
        type: "",
        nature: "",
        start_date: "",
        days: "",
        report_level: "",
        abstract: "",
        content: "",
        teaching_studio_id: localStorage.getItem("studioId"),
        cover_id: "",
        cover_url: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 0, max: 60, message: "最多60个字", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        start_date: [{ required: true, message: "请选择开展日期", trigger: "change" }],
        report_level: [{ required: true, message: "请选择活动级别", trigger: "change" }],
        abstract: [{ required: true, message: "请填写摘要", trigger: "blur" },
        { min: 0, max: 64, message: "最多64个字", trigger: "blur" },],
        content: [{ required: true, message: "请填写内容", trigger: "blur" }],
      },
      certificatList: [
        { id: 1, name: "国家级" },
        { id: 2, name: "省级" },
        { id: 3, name: "市级" },
        { id: 4, name: "区县级" },
        { id: 5, name: "校级" },
        { id: 6, name: "其他" },
      ],
      dcUploadConfig: {
        key: "",
        notify_url: "",
      },
      routeName: "",
    };
  },
  created() {
    this.routeName = this.$route.name
    this.getDcUploadConfig()
    this._articleTemplate()
    //获取文章分类
    this.getArticleType();
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    async getDetails() {
      const res = await this.$axios.get("/teachingStudioReport/details", {
        params: {
          id: this.$route.query.id
        }
      });
      this.form = res.data.data
      setTimeout(() => {
        this.changetype(this.form.type)
      }, 1000);
    },
    changetype(val) {
      this.typeList.forEach(item => {
        if (item.id == val) {
          this.typeList2 = item.children
        }
      })
    },
    changeTemp() {
      if (this.form.content) {
        this.$confirm('切换模板会清空所有内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.oldId = this.tempId
          this.templateList.forEach(item => {
            if (item.id == this.tempId) {
              this.form.content = item.cont
            }
          })
        }).catch(() => {
          this.tempId = this.oldId
        });
      } else {
        this.oldId = this.tempId
        this.templateList.forEach(item => {
          if (item.id == this.tempId) {
            this.form.content = item.cont
          }
        })
      }
    },
    _articleTemplate() {
      articleTemplate({ limit: 999 }).then(res => {
        this.templateList = res.data.data
      })
    },
    //提交
    submitForm: throttle(function (formName) {
      if (this.save_loading_btn) {
        return false;
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save_loading_btn = true;
          if (this.$route.query.id) {
            this.$axios.put("teachingStudioReport/edit", this.form).then((res) => {
              this.save_loading_btn = false;
              this.$message.success("修改成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1000);
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          } else {
            this.$axios.post("teachingStudioReport/add", this.form).then((res) => {
              this.save_loading_btn = false;
              this.$message.success("发布成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 1000);
            }).catch((err) => {
              this.save_loading_btn = false;
            });
          }
        }
      });
    }),
    //   取消
    resetForm() {
      this.$router.go(-1);
    },

    //获取文章分类
    getArticleType() {
      let obj = {
        id: 41
      };
      this.$axios.get("index/dicTreeList", { params: obj, }).then((res) => {
        this.typeList = res.data.data;
      });
    },
    //获取金山云配置
    async getDcUploadConfig() {
      const res = await this.$axios.get("/k3sSetting");
      const data = res.data.data
      this.dcUploadConfig.key = data.key;
      this.dcUploadConfig.notify_url = data.callBack;
    },
    //上传成功后的回调
    async dcUploadSuccess(data) {
      if (!data.upload) return;
      const resData = data.upload.CompleteMultipartUploadResult;
      const etag = resData.ETag.replace(/"/g, "");
      const res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag
        }
      })
      this.form.cover_url = resData.Location;
      this.form.cover_id = res.data.data.id;
      console.log(this.form);
      this.$refs["form"].validateField("cover_id")
    },
  },
};
</script>
<style lang="less" scoped>
.pushBox {

  .ArrBox {
    .title {
      height: 48px;
      font-size: 14px;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        span {
          color: #ff8201;
        }
      }
    }

    //盒子
    .BoxItem {
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 6px;
      padding: 30px 50px;

      h4 {
        margin-bottom: 24px;
        margin-left: 48px;
      }
    }
  }
}

.upload-img-box {
  width: 160px;
  height: 100px;
  background: #f7f7f7;
  border: 1px solid #ececec;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;

  .upload-img {
    width: 160px;
    height: 100px;
  }

  .upload-noData {
    font-size: 30px;
  }
}
</style>